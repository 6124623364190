<template>
  <div>
    <div class="container">
      <br>
      <div class="columns">
        <div class="column">
          <div>
            <h3 class="title is-3">Você digitou o link correto?</h3>
            <p>
              Informe o nome da sua organização apos a barra /
            </p>
            <p>
              Exemplo: chamados.suporte.es<strong>/suaorganização</strong>
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="columns">
        <div class="column is-4">
          <div class="box">
            PMST
          </div>
        </div>
        <div class="column is-4">
          <div class="box">
            INMA
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>


<script>
  export default {
    
  }
</script>

<style scoped>
</style>